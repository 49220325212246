import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Images = ({imageOne, imageTwo,className}) => (
  <div className={"image--cards p20 m-p0 " + className}>
    <div className="max-1600 ma flex">
      <div className="col w-100 p20">
        <div className="max-750 w-100">
          <div className="ratio-2-1 pos-rel bg-grey">
            <GatsbyImage className='bg-image' image={imageOne.gatsbyImageData} alt='Our Story' />
          </div>
        </div>
      </div>
      <div className="col w-100 p20">
        <div className="max-350 mla mt50p">
          <div className="ratio-1-2 pos-rel bg-grey">
            <GatsbyImage className='bg-image' image={imageTwo.gatsbyImageData} alt='Our Story' />
          </div>
        </div>
      </div>
    </div>
  </div> 
) 
export default Images
