import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import ParallaxBanner from "../components/parallaxBanner"
import FeaturedProject from "../components/featuredProject"
import TextBanner from "../components/textBanner"
import Images from "../components/images"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Quotation from "../components/quotation"
import Spacer from "../components/spacer"
import KeyInformation from "../components/keyInformation"

class StoryPage extends React.Component {
	render() {
		var data = this.props.data.allDatoCmsOurStory.nodes[0];
		var next = this.props.data.allDatoCmsExpertise.nodes[0];
		const values = this.props.data.allDatoCmsHomepage.nodes[0];
		return (
			<Layout internal={this.props.location.state?.internal}>
				<SEO title="Atlantis Property Group" />
				<Header />
				<ParallaxBanner title={data.heroTitle} image={data.heroImage} />
				<Quotation quote={data.quotation} author={data.textAuthor} image={data.portrait} position='Director' />
				<TextBanner spacerTop={false} spacer='m-hide' content={data.contentBlock} />
				<Images imageOne={data.imageOne} imageTwo={data.imageTwo} />
				<Spacer />
				<TextBanner title={data.title} spacerBottom={false} spacerTop={false} spacer='m-hide' content={data.contentBannerBottom} />
				<KeyInformation spacerTop={false} titleOne={values.infoTitleOne} copyOne={values.infoContentOne} copyTwo={values.infoContentTwo} copyThree={values.infoContentThree} titleTwo={values.infoTitleTwo} titleThree={values.infoTitleThree} />
				<FeaturedProject image={next.heroImage} title={next.heroTitle} slug='expertise' caption='Next Page' />
			</Layout>
		)
	}
}




export default StoryPage

export const query = graphql`
  query StoryQuery {
	allDatoCmsHomepage {
    	nodes {
			infoContentOne
			infoContentThree
			infoContentTwo
			infoTitleOne
			infoTitleTwo
			infoTitleThree
		}
	}
  	allDatoCmsOurStory {
	    nodes {
	    	imageTwo {
	      	gatsbyImageData
	      }
		  title
		  contentBannerBottom
		  portrait{
			gatsbyImageData
		  }
      	imageOne {
	      	gatsbyImageData
	      }
	    	quotation
      	textAuthor
      	textPosition
      	contentBlock
	      heroTitle
	      heroImage {
	      	gatsbyImageData
	      }
	    }
	  }
	  allDatoCmsExpertise {
	    nodes {
	      heroTitle
	      heroImage {
	      	gatsbyImageData
	      }
	    }
	  }
  }
`
