import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"
import AnimateInView from "../components/animation/animateInView"

function animation(inView, intersectionObserver) {
    if (intersectionObserver?.target) {
        if(inView){
         intersectionObserver.target.classList.add('in-view')
        } else{
         intersectionObserver.target.classList.remove('in-view')
        }
    }
}

const Quotation = ({quote, image, position, author}) => (
  <div className="quotation p20  ">
      <Spacer />
      <div className="max-600 ma text-center">
        <p className='h3 black m-mt0 m-mb50 '>“{quote}”</p>
        <div className=" m-max-500  ma">
        <div className="ratio-1-2 bg-grey pos-rel overflow-hidden">
          <AnimateInView  animation={animation} className="will-zoom bg-image">
            <GatsbyImage className='project-image bg-image' image={image.gatsbyImageData} alt={author} />
          </AnimateInView>
        </div>
        </div>
        <p className="h2 m0 mt40 mb40 black m-max-500 ma m-mb10">{author}</p>
        <p className="m0 uppercase medium mt20 m-mt0  ">{position}</p>
      </div>
      <Spacer />
  </div> 
) 
export default Quotation
